/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import {
  selectDynamicLayout,
  selectStreamGoRoom,
  setLowerThirdsPercentage,
} from '../../../redux/slices/room';
import { StreamingView } from '../../../redux/streamGoApi/enums';
import { useWindowSize } from '../hooks/useWindowSize';

interface ILowerThirdsContainer {
  backgroundColor: string;
  color: string;
  fontSize: number;
}

export const Container: any = styled(Box)(
  ({
    backgroundColor = 'black',
    color = 'white',
    fontSize = 18,
  }: ILowerThirdsContainer) => ({
    alignItems: 'center',
    backgroundColor,
    bottom: 0,
    color,
    padding: '30px',
    fontWeight: 'bold',
    fontFamily: 'Lato, Arial',
    fontSize: `${fontSize}px`,
    display: 'flex',
    height: 'auto',
    textAlign: 'center',
    justifyContent: 'center',
    position: 'absolute',
    width: '100vw',
    zIndex: 1111111,
    whiteSpace: 'pre-line',
  }),
);

const hiddenStreamingViews = [StreamingView.PreRoll, StreamingView.PostRoll];

export const LowerThirds = () => {
  const dynamicLayout = useSelector(selectDynamicLayout) ?? {};
  const room = useSelector(selectStreamGoRoom);
  const dispatch = useDispatch();
  const containerRef = useRef<HTMLDivElement>(null);
  const [characters, setCharacters] = useState(0);
  const [fontSize, setFontSize] = useState(0);
  const { height, width } = useWindowSize();

  useEffect(() => {
    setFontSize(Math.max(26, 44 - Math.floor(characters / 100) * 2));
  }, [characters]);

  useEffect(() => {
    if (containerRef.current) {
      const containerHeight = containerRef.current.clientHeight;
      const screenHeight = window.innerHeight;
      const percentage = (containerHeight / screenHeight) * 100;
      dispatch(setLowerThirdsPercentage(percentage));
    }
  }, [
    dynamicLayout.lowerThirds,
    containerRef.current?.clientHeight,
    dispatch,
    width,
    height,
  ]);

  useEffect(() => {
    if (dynamicLayout.lowerThirds?.text) {
      setCharacters(dynamicLayout.lowerThirds?.text?.length);
    } else {
      setCharacters(0);
      dispatch(setLowerThirdsPercentage(0));
    }
  }, [dispatch, dynamicLayout.lowerThirds?.text]);

  if (
    dynamicLayout.lowerThirds?.text &&
    !hiddenStreamingViews.includes(room?.StreamingView ?? StreamingView.Unset)
  ) {
    const { backgroundColor, color, text } = dynamicLayout.lowerThirds;
    return (
      <>
        <style>
          {
            "@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Oxygen+Mono&family=Ubuntu+Mono&display=swap');"
          }
        </style>
        <Container
          backgroundColor={backgroundColor}
          color={color}
          fontSize={fontSize}
          ref={containerRef}
        >
          {text}
        </Container>
      </>
    );
  }

  return null;
};
