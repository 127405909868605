import { useState, useCallback, useEffect } from 'react';
import Checkbox from '@mui/material/Checkbox';
import { ColorPicker } from '../ColourPicker';
import { INameTags } from '../types';

export const useNameTagSettings = (initialNameTags?: INameTags) => {
  const [nameTagChecked, setNameTagChecked] = useState<boolean>(
    !!initialNameTags,
  );
  const [nameTagForeground, setNameTagForeground] = useState<string>(
    initialNameTags?.color || '#ffffff',
  );
  const [nameTagBackground, setNameTagBackground] = useState<string>(
    initialNameTags?.background || '#000000',
  );

  const handleNameTag = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) =>
      setNameTagChecked(e.target.checked),
    [],
  );

  useEffect(() => {
    if (initialNameTags) {
      setNameTagChecked(true);
      setNameTagForeground(initialNameTags.color);
      setNameTagBackground(initialNameTags.background);
    } else {
      setNameTagChecked(false);
      setNameTagForeground('#ffffff');
      setNameTagBackground('#000000');
    }
  }, [initialNameTags]);

  const getModel = () =>
    nameTagChecked
      ? { color: nameTagForeground, background: nameTagBackground }
      : undefined;

  return {
    nameTagBlocks: [
      {
        title: 'Active',
        element: <Checkbox checked={nameTagChecked} onChange={handleNameTag} />,
      },
      {
        active: nameTagChecked,
        title: 'Font Colour',
        element: (
          <ColorPicker
            colorSelected={nameTagForeground}
            setColor={setNameTagForeground}
          />
        ),
      },
      {
        active: nameTagChecked,
        title: 'Background Colour',
        element: (
          <ColorPicker
            colorSelected={nameTagBackground}
            setColor={setNameTagBackground}
          />
        ),
      },
    ],
    getNameTagModel: getModel,
  };
};
