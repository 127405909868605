import { useSelector } from 'react-redux';
import { useVisualStableUpdate } from '@livekit/components-react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { selectLowerThirdsPercentage } from '../../../../redux/slices/room';
import { CarouselLayout } from '../../../LiveKit/CarouselLayout';
import { ParticipantTileMinimal } from '../../../LiveKit/ParticipantTileMinimal';
import { StreamType } from '../../enums';
import { useGetTracks, useSetLiveView } from '../../hooks';
import { MainTrack } from './MainTrack';

const FocusLayout = styled(Box)({
  '& > video': {
    objectFit: 'contain!important',
    background: 'unset',
    maxHeight: '100vh',
  },

  '& > aside': {
    justifyContent: 'center',
  },
});

interface IPinnedView {
  streamType: StreamType;
}

export const PinnedView = ({ streamType }: IPinnedView) => {
  const lowerThirdsPercentage = useSelector(selectLowerThirdsPercentage);
  const { sortedTracks, tracks } = useGetTracks();
  const mainTrack = tracks[streamType];

  if (streamType === StreamType.Webcam) {
    sortedTracks.shift();
  }

  const remainingTracks = useVisualStableUpdate(sortedTracks, 5);
  useSetLiveView({
    component: 'pinnedView',
    videoTracks:
      streamType === StreamType.Webcam
        ? [mainTrack, ...remainingTracks]
        : remainingTracks,
  });

  if (!mainTrack) {
    return <></>;
  }

  if (sortedTracks.length === 0) {
    return <MainTrack mainTrack={mainTrack} streamType={streamType} />;
  }

  return (
    <FocusLayout className="lk-focus-layout">
      <CarouselLayout
        style={{ height: `${100 - lowerThirdsPercentage}vh` }}
        tracks={remainingTracks}
      >
        <ParticipantTileMinimal className="videoCam" />
      </CarouselLayout>
      <MainTrack
        mainTrack={mainTrack}
        streamType={streamType}
        style={{ height: `${100 - lowerThirdsPercentage}vh` }}
      />
    </FocusLayout>
  );
};
