import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RoomEvent } from 'livekit-client';
import { useRoomContext } from '@livekit/components-react';
import EgressHelper from '@livekit/egress-sdk';
import { UnknownAction } from '@reduxjs/toolkit';
import { fetchEventToken, setEventId } from '../../redux/slices/config';
import { selectLowerThirdsPercentage } from '../../redux/slices/room';
import {
  BackgroundImage,
  Logo,
  LowerThirds,
  VideoBorder,
} from '../DynamicLayout/StreamingView';
import { useBroadcastLiveView, useGetStreamingTemplate } from './hooks';

export function CompositeTemplate() {
  const lowerThirdsPercentage = useSelector(selectLowerThirdsPercentage);
  const room = useRoomContext();
  const dispatch = useDispatch();
  const Component = useGetStreamingTemplate(room.name);
  useBroadcastLiveView();

  useEffect(() => {
    if (room.name) {
      dispatch(setEventId(parseInt(room.name, 10)));
      dispatch(
        fetchEventToken(parseInt(room.name, 10)) as unknown as UnknownAction,
      );
    }
  }, [dispatch, room.name]);

  useEffect(() => {
    if (room) {
      EgressHelper.setRoom(room);

      // start recording when there's already a track published
      let hasTrack = false;
      const participants = Array.from(room.remoteParticipants.values());

      participants.forEach(p => {
        if (!hasTrack && p.trackPublications.size > 0) {
          hasTrack = true;
        }
      });

      if (hasTrack) {
        EgressHelper.startRecording();
      } else {
        room.once(RoomEvent.TrackSubscribed, () =>
          EgressHelper.startRecording(),
        );
      }
    }
  }, [room]);

  const containerClass = 'roomContainer';

  return (
    <BackgroundImage>
      <div
        className={containerClass}
        style={{ height: `${100 - lowerThirdsPercentage}vh` }}
      >
        {Component}
      </div>
      <Logo />
      <VideoBorder />
      <LowerThirds />
    </BackgroundImage>
  );
}
