import { useSelector } from 'react-redux';
import { TrackReference } from '@livekit/components-react';
import { selectLowerThirdsPercentage } from '../../../../redux/slices/room';
import { NameTag } from '../../../DynamicLayout/StreamingView';
import { StreamType } from '../../enums';
import { useGetTracks } from '../../hooks';
import { useSetLiveView } from '../../hooks/useSetLiveView';
import { FullScreenVideoTrack } from '../../Styled';

interface ISingleTrackView {
  disableSetLive?: boolean;
  streamType: StreamType;
}

export const SingleTrackView = ({
  disableSetLive = false,
  streamType,
}: ISingleTrackView) => {
  const lowerThirdsPercentage = useSelector(selectLowerThirdsPercentage);

  const { tracks } = useGetTracks();
  const videoTrack = tracks[streamType];

  useSetLiveView({
    component: 'singleTrackView',
    disableSetLive,
    streamType,
    videoTracks: [videoTrack],
  });

  if (!videoTrack) {
    return null;
  }

  console.log('HERE');

  return (
    <>
      <FullScreenVideoTrack
        className={streamType === StreamType.Webcam ? 'videoCam' : undefined}
        height={
          streamType === StreamType.Webcam
            ? `${100 - lowerThirdsPercentage}vh!important` // '100vh' - test - do we want 100vh?
            : `${100 - lowerThirdsPercentage}vh!important`
        }
        trackRef={videoTrack as TrackReference}
      />
      {streamType === StreamType.Webcam ? (
        <NameTag participant={videoTrack.participant} />
      ) : null}
    </>
  );
};
