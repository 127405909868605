import { Block, Section, Title } from './styled';

interface IBlock {
  active?: boolean;
  element: JSX.Element;
  title: string;
}

interface IDynamicLayoutSection {
  blocks: IBlock[];
  title?: string;
}

export const DynamicLayoutSection = ({
  blocks,
  title,
}: IDynamicLayoutSection) => (
  <Section>
    <Title>{title}</Title>
    {blocks
      .filter(block => block.active !== false)
      .map(block => (
        <Block key={block.title}>
          {block.title ? <div>{block.title}</div> : null}
          <div style={{ width: block.title ? 'auto' : '100%' }}>
            {block.element}
          </div>
        </Block>
      ))}
  </Section>
);
