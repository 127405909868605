/* eslint-disable max-len */
/* eslint-disable max-lines */

import {
  StreamingView,
  StreamingViewType,
} from '../../redux/streamGoApi/enums';

/* eslint-disable camelcase */
export const streamingViews: Record<StreamingView, number> = {
  [StreamingView.Unset]: -1,
  [StreamingView.WebCamsActiveSpeaker]: 1,
  [StreamingView.WebCamsGrid]: 2,
  [StreamingView.FullScreenSlides]: 3,
  [StreamingView.FullScreenSlidesNoSound]: 35,
  [StreamingView.WebCamsActiveSpeakerPinned]: 36,
  [StreamingView.SlidesPinned]: 37,
  [StreamingView.SlidesWebCamSplit8020Wrsl]: 28,
  [StreamingView.FullScreenScreenShare]: 14,
  [StreamingView.ScreenSharePinned]: 38,
  [StreamingView.ScreenshareWebCamSplit8020Wrsl]: 32,
  [StreamingView.SlidesWebCamPipTopRight]: 11,
  [StreamingView.SlidesWebCamPipBottomRight]: 13,
  [StreamingView.SlidesWebcamSplit11Wrsl]: 41,
  [StreamingView.SlidesWebcamSplit169Wrsl]: 44,
  [StreamingView.ScreenshareWebCamPipTopRight]: 22,
  [StreamingView.ScreenshareWebCamPipBottomRight]: 24,
  [StreamingView.ScreenshareWebcamSplit11Wrsl]: 48,
  [StreamingView.ScreenshareWebcamSplit169Wrsl]: 50,
  [StreamingView.PreRoll]: 25,
  [StreamingView.PostRoll]: 26,
};

export const priorityStreamingViews: number[] = [
  StreamingView.WebCamsActiveSpeaker,
  StreamingView.WebCamsGrid,
  StreamingView.SlidesWebCamSplit8020Wrsl,
  StreamingView.FullScreenSlides,
  StreamingView.ScreenshareWebCamSplit8020Wrsl,
  StreamingView.FullScreenScreenShare,
];

export const otherStreamingViews: number[] = [
  StreamingView.FullScreenSlidesNoSound,
  StreamingView.WebCamsActiveSpeakerPinned,
  StreamingView.SlidesPinned,
  StreamingView.ScreenSharePinned,
  StreamingView.SlidesWebcamSplit169Wrsl,
  StreamingView.ScreenshareWebcamSplit169Wrsl,
  StreamingView.SlidesWebcamSplit11Wrsl,
  StreamingView.ScreenshareWebcamSplit11Wrsl,
  StreamingView.SlidesWebCamPipTopRight,
  StreamingView.SlidesWebCamPipBottomRight,
  StreamingView.ScreenshareWebCamPipTopRight,
  StreamingView.ScreenshareWebCamPipBottomRight,
  StreamingView.PreRoll,
  StreamingView.PostRoll,
];

export const streamingViewsDesc: Record<number, string> = {
  [StreamingView.WebCamsActiveSpeaker]: 'Webcams Active Speaker',
  [StreamingView.WebCamsGrid]: 'Webcams Grid',
  [StreamingView.FullScreenSlides]: 'Full Screen Slides',
  [StreamingView.FullScreenSlidesNoSound]: 'Full Screen Slides (No Sound)',
  [StreamingView.WebCamsActiveSpeakerPinned]: 'WebCams Active Speaker Pinned',
  [StreamingView.SlidesPinned]: 'Slides Pinned',
  [StreamingView.SlidesWebCamSplit8020Wrsl]: 'Slides/Webcam 80/20',
  [StreamingView.FullScreenScreenShare]: 'Full Screen Screenshare',
  [StreamingView.ScreenSharePinned]: 'Screenshare Pinned',
  [StreamingView.ScreenshareWebCamSplit8020Wrsl]: 'Screenshare/Webcam 80/20',
  [StreamingView.SlidesWebcamSplit11Wrsl]: 'Slides/Webcam (1:1)',
  [StreamingView.SlidesWebcamSplit169Wrsl]: 'Slides/Webcam (16:9)',
  [StreamingView.ScreenshareWebcamSplit11Wrsl]: 'Screenshare/Webcam (1:1)',
  [StreamingView.ScreenshareWebcamSplit169Wrsl]: 'Screenshare/Webcam (16:9)',
  [StreamingView.SlidesWebCamPipTopRight]: 'Slides/Webcam PIP Top',
  [StreamingView.SlidesWebCamPipBottomRight]: 'Slides/Webcam PIP Bottom',
  [StreamingView.ScreenshareWebCamPipTopRight]: 'Screenshare/Webcam PIP Top',
  [StreamingView.ScreenshareWebCamPipBottomRight]:
    'Screenshare/Webcam PIP Bottom',
  [StreamingView.PreRoll]: 'Pre-Roll Video',
  [StreamingView.PostRoll]: 'Post Event Message',
};

const posterUrl = 'https://assets.streamgo.vc/streaming-views/';

const WebCamsActiveSpeaker = `${posterUrl}WebCamsActiveSpeaker.png`;
const WebCamsGrid = `${posterUrl}WebCamsGrid.png`;
const FullScreenSlides = `${posterUrl}FullScreenSlides.png`;
const FullScreenSlidesNoSound = `${posterUrl}FullScreenSlidesNoSound.png`;
const WebCamsActiveSpeakerPinned = `${posterUrl}WebCamsActiveSpeakerPinned.png`;
const SlidesPinned = `${posterUrl}SlidesPinned.png`;
const SlidesWebCamPipTopRight = `${posterUrl}SlidesWebCamPIPTopRight.png`;
const SlidesWebCamSplit8020Wrsl = `${posterUrl}SlidesWebCamSplit_80_20_wrsl.png`;
const SlidesWebCamPipBottomRight = `${posterUrl}SlidesWebCamPIPBottomRight.png`;
const SlidesWebcamSplit11Wrsl = `${posterUrl}SlidesWebCamInline_1_1_16_9_wrsl.png`;
const SlidesWebcamSplit169Wrsl = `${posterUrl}SlidesWebCamSplit_50_50_wrsl.png`;
const FullScreenScreenShare = `${posterUrl}FullScreenScreenShare.png`;
const ScreenSharePinned = `${posterUrl}ScreenSharePinned.png`;
const ScreenshareWebCamSplit8020Wrsl = `${posterUrl}ScreenshareWebCamSplit_80_20_wrsl.png`;
const ScreenshareWebCamPipTopRight = `${posterUrl}ScreenshareWebCamPIPTopRight.png`;
const ScreenshareWebCamPipBottomRight = `${posterUrl}ScreenshareWebCamPIPBottomRight.png`;
const ScreenshareWebcamSplit11Wrsl = `${posterUrl}ScreenshareWebCamInline_1_1_16_9_wrsl.png`;
const ScreenshareWebcamSplit169Wrsl = `${posterUrl}ScreenshareWebCamSplit_50_50_wrsl.png`;
const PreRoll = `${posterUrl}Preroll.png`;
const PostRoll = `${posterUrl}PostRoll.png`;

export const streamingViewsImage: Record<number, string> = {
  [StreamingView.WebCamsActiveSpeaker]: WebCamsActiveSpeaker,
  [StreamingView.WebCamsGrid]: WebCamsGrid,
  [StreamingView.FullScreenSlides]: FullScreenSlides,
  [StreamingView.FullScreenSlidesNoSound]: FullScreenSlidesNoSound,
  [StreamingView.WebCamsActiveSpeakerPinned]: WebCamsActiveSpeakerPinned,
  [StreamingView.SlidesPinned]: SlidesPinned,
  [StreamingView.SlidesWebCamSplit8020Wrsl]: SlidesWebCamSplit8020Wrsl,
  [StreamingView.SlidesWebcamSplit11Wrsl]: SlidesWebcamSplit11Wrsl,
  [StreamingView.SlidesWebcamSplit169Wrsl]: SlidesWebcamSplit169Wrsl,
  [StreamingView.SlidesWebCamPipTopRight]: SlidesWebCamPipTopRight,
  [StreamingView.SlidesWebCamPipBottomRight]: SlidesWebCamPipBottomRight,
  [StreamingView.FullScreenScreenShare]: FullScreenScreenShare,
  [StreamingView.ScreenSharePinned]: ScreenSharePinned,
  [StreamingView.ScreenshareWebCamSplit8020Wrsl]:
    ScreenshareWebCamSplit8020Wrsl,
  [StreamingView.ScreenshareWebcamSplit11Wrsl]: ScreenshareWebcamSplit11Wrsl,
  [StreamingView.ScreenshareWebcamSplit169Wrsl]: ScreenshareWebcamSplit169Wrsl,
  [StreamingView.ScreenshareWebCamPipTopRight]: ScreenshareWebCamPipTopRight,
  [StreamingView.ScreenshareWebCamPipBottomRight]:
    ScreenshareWebCamPipBottomRight,
  [StreamingView.PreRoll]: PreRoll,
  [StreamingView.PostRoll]: PostRoll,
};

export const slidesViews: StreamingView[] = [
  StreamingView.FullScreenSlides,
  StreamingView.FullScreenSlidesNoSound,
  StreamingView.SlidesPinned,
  StreamingView.SlidesWebCamSplit8020Wrsl,
  StreamingView.SlidesWebcamSplit11Wrsl,
  StreamingView.SlidesWebcamSplit169Wrsl,
  StreamingView.SlidesWebCamPipTopRight,
  StreamingView.SlidesWebCamPipBottomRight,
];

export const screenshareViews: StreamingView[] = [
  StreamingView.FullScreenScreenShare,
  StreamingView.ScreenSharePinned,
  StreamingView.ScreenshareWebCamSplit8020Wrsl,
  StreamingView.ScreenshareWebcamSplit11Wrsl,
  StreamingView.ScreenshareWebcamSplit169Wrsl,
  StreamingView.ScreenshareWebCamPipTopRight,
  StreamingView.ScreenshareWebCamPipBottomRight,
];

export const streamingViewTitle = {
  [StreamingViewType.StreamingView]: 'Streaming View',
  [StreamingViewType.StreamingViewDefault]: 'Default View',
};
