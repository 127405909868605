import React, { useCallback, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';
import { selectRoomName } from '../../redux/slices/room';
import { DynamicLayout } from '../DynamicLayout';

const BottomRow = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  marginBottom: '10px',
});

const CircularProgressStyled = styled(CircularProgress)({
  width: '16px',
  height: '16px',
  marginLeft: '6px',
  top: '2px',
  position: 'relative',
});

interface IDynamicLayoutRef {
  saveDynamicLayout: () => void;
}

export const SettingsDynamicLayout = () => {
  const [isLoading, setIsLoading] = useState(false);
  const roomName = useSelector(selectRoomName) ?? '';
  const dynamicLayoutRef = useRef<IDynamicLayoutRef>(null);

  const handleClickSave = useCallback(
    () => dynamicLayoutRef?.current?.saveDynamicLayout(),
    [],
  );

  return roomName ? (
    <>
      <DynamicLayout
        ref={dynamicLayoutRef}
        roomId={roomName}
        setIsSaving={setIsLoading}
      />
      <BottomRow>
        <Button color="primary" onClick={handleClickSave} variant="contained">
          <span>Save Settings</span>
          {isLoading ? <CircularProgressStyled size={16} /> : null}
        </Button>
      </BottomRow>
    </>
  ) : null;
};
