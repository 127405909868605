import React from 'react';
import { useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import {
  selectDynamicLayout,
  selectLowerThirdsPercentage,
} from '../../../redux/slices/room';

interface IDynamicLogo {
  lowerThirdsPercentage: number;
}

const DynamicLogo = styled('img')(
  ({ lowerThirdsPercentage }: IDynamicLogo) => ({
    position: 'absolute',
    zIndex: 101,
    '&.topLeft': {
      left: '5%',
      top: '5%',
    },
    '&.topRight': {
      right: '5%',
      top: '5%',
    },
    '&.bottomLeft': {
      left: '5%',
      bottom: `${lowerThirdsPercentage + 5}%`,
    },
    '&.bottomRight': {
      right: '5%',
      bottom: `${lowerThirdsPercentage + 5}%`,
    },
  }),
);

export const Logo = () => {
  const lowerThirdsPercentage = useSelector(selectLowerThirdsPercentage) ?? 0;
  const dynamicLayout = useSelector(selectDynamicLayout) ?? {};

  return (
    <>
      {dynamicLayout?.logo ? (
        <DynamicLogo
          alt="logo"
          className={`${dynamicLayout.logo.position}`}
          lowerThirdsPercentage={lowerThirdsPercentage}
          src={dynamicLayout.logo.url}
          style={{ width: `${dynamicLayout.logo.width.toString()}%` }}
        />
      ) : (
        <></>
      )}
    </>
  );
};
