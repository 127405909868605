/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-no-bind */
import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import {
  selectDraftLowerThirdText,
  setDynamicLayoutQuestion,
} from '../../../redux/slices/room';
import { ColorPicker } from '../ColourPicker';
import { ILowerThirds } from '../types';

export interface IUseLowerThirdsSettings {
  lowerThirds?: ILowerThirds;
}

export const TextareaAutosizeStyled = styled(TextareaAutosize)({
  width: '100%',
  padding: '4px 4px 2px 6px',
  fontFamily: 'arial',
  marginBottom: 0,
});

export const ClearButton: any = styled(Button)({
  float: 'right',
  border: '1px solid rgb(68 192 185) !important',
  color: '#44c0b9',
  fontSsize: '12px !important',
});

export const useLowerThirdsSettings = ({
  lowerThirds,
}: IUseLowerThirdsSettings) => {
  const dispatch = useDispatch();
  const draftText = useSelector(selectDraftLowerThirdText);
  const [latestDraft, setLatestDraft] = useState('');
  const [text, setText] = useState(lowerThirds?.text ?? '');
  const [backgroundColor, setBackgroundColor] = useState<string>(
    lowerThirds?.backgroundColor || '#000000',
  );
  const [color, setColor] = useState<string>(lowerThirds?.color || '#ffffff');

  const handleClear = useCallback(() => {
    setText('');
  }, []);

  useEffect(() => {
    if (lowerThirds) {
      setBackgroundColor(lowerThirds?.backgroundColor ?? '');
      setColor(lowerThirds?.color ?? '');
      setText(lowerThirds?.text ?? '');
    }
  }, [lowerThirds]);

  useEffect(() => {
    if (draftText) {
      setText(draftText);
      setLatestDraft(draftText);
    }
  }, [draftText]);

  useEffect(() => {
    if (latestDraft && latestDraft !== text) {
      setLatestDraft('');
      dispatch(setDynamicLayoutQuestion(''));
    }
  }, [dispatch, latestDraft, text]);

  const getModel = (): ILowerThirds | undefined =>
    backgroundColor ? { backgroundColor, color, text: text.trim() } : undefined;

  return {
    lowerThirdsBlocks: [
      {
        title: 'Font Colour',
        element: <ColorPicker colorSelected={color} setColor={setColor} />,
      },
      {
        title: 'Background Colour',
        element: (
          <ColorPicker
            colorSelected={backgroundColor}
            setColor={setBackgroundColor}
          />
        ),
      },
      {
        title: '',
        element: (
          <>
            <TextareaAutosizeStyled
              maxLength={1000}
              minRows={3}
              onChange={e => setText(e.target.value)}
              placeholder="Enter lower third text here"
              value={text}
            />
            <ClearButton
              className="btn-sm"
              onClick={handleClear}
              size="small"
              variant="outlined"
            >
              clear
            </ClearButton>
          </>
        ),
      },
    ],
    getLowerThirdsModel: getModel,
  };
};
