import {
  SideBySideStreamingViews,
  StreamingView,
} from '../../../../redux/streamGoApi/enums';
import { StreamType } from '../../enums';

interface ISideBySideViewOptions {
  inline?: boolean;
  leftStream: StreamType;
  leftWidth?: number;
  ratio: '16_9' | '1_1';
  rightStream: StreamType;
  rightWidth?: number;
}

export const sideBySideConfig: Record<
  SideBySideStreamingViews,
  ISideBySideViewOptions
> = {
  [StreamingView.SlidesWebCamSplit8020Wrsl]: {
    leftStream: StreamType.Slides,
    rightStream: StreamType.Webcam,
    leftWidth: 80,
    rightWidth: 20,
    ratio: '1_1',
  },
  [StreamingView.SlidesWebcamSplit11Wrsl]: {
    leftStream: StreamType.Slides,
    rightStream: StreamType.Webcam,
    ratio: '1_1',
    inline: true,
  },
  [StreamingView.SlidesWebcamSplit169Wrsl]: {
    leftStream: StreamType.Slides,
    rightStream: StreamType.Webcam,
    ratio: '16_9',
    inline: true,
  },
  [StreamingView.ScreenshareWebCamSplit8020Wrsl]: {
    leftStream: StreamType.Screenshare,
    rightStream: StreamType.Webcam,
    leftWidth: 80,
    rightWidth: 20,
    ratio: '1_1',
  },
  [StreamingView.ScreenshareWebcamSplit11Wrsl]: {
    leftStream: StreamType.Screenshare,
    rightStream: StreamType.Webcam,
    ratio: '1_1',
    inline: true,
  },
  [StreamingView.ScreenshareWebcamSplit169Wrsl]: {
    leftStream: StreamType.Screenshare,
    rightStream: StreamType.Webcam,
    ratio: '16_9',
    inline: true,
  },
};
