import {
  TrackReference,
  TrackReferenceOrPlaceholder,
} from '@livekit/components-react';
import { styled } from '@mui/material/styles';
import { NameTag } from '../../../DynamicLayout/StreamingView';
import { ParticipantTileMinimal } from '../../../LiveKit/ParticipantTileMinimal';
import { StreamType } from '../../enums';
import { VideoContainer } from '../../Styled';

interface IMainTrack extends React.HTMLAttributes<HTMLMediaElement> {
  mainTrack: TrackReferenceOrPlaceholder;
  streamType: StreamType;
}

export const MainTrackContainer = styled(VideoContainer)({
  '& .lk-participant-media-video[data-lk-orientation=landscape]': {
    objectFit: 'contain!important',
  },
});

export const MainTrack = ({ mainTrack, streamType, ...props }: IMainTrack) => {
  return (
    <MainTrackContainer {...props}>
      <ParticipantTileMinimal
        className={streamType === StreamType.Webcam ? 'videoCam' : undefined}
        trackRef={mainTrack as TrackReference}
      />
      {streamType === StreamType.Webcam ? (
        <NameTag participant={mainTrack.participant} />
      ) : null}
    </MainTrackContainer>
  );
};
