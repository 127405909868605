/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { IPlaylistItem } from '../../../../redux/platformApi';
import { selectLowerThirdsPercentage } from '../../../../redux/slices/room';
import { useSetLiveView } from '../../hooks/useSetLiveView';
import { FullscreenVideoContained } from '../../Styled';
import { IPlayListVideoItem } from './types';

export const PlaylistVideoView = ({
  isMediaMode,
  nextPlaylistItem,
  onPlaying,
  playlist,
  video,
}: IPlayListVideoItem) => {
  const lowerThirdsPercentage = useSelector(selectLowerThirdsPercentage);
  const [videoItem, setVideoItem] = useState<IPlaylistItem>();
  const [duration, setDuration] = useState<number>();
  const [currentPos, setCurrentPos] = useState<number>();
  const timeRemainingRef = useRef<number>(null);

  useEffect(() => {
    const item = playlist?.items?.find(x => isMediaMode.data.id === x.data.id);
    if (item?.data?.id !== videoItem?.data?.id) {
      setVideoItem(item);
    } else {
      setCurrentPos(item?.data?.pos ?? 0);
    }
  }, [isMediaMode.data.id, playlist?.items, videoItem?.data?.id]);

  useEffect(() => {
    const interval = window.setInterval(() => {
      if (
        timeRemainingRef?.current &&
        timeRemainingRef?.current > 5 &&
        video?.current
      ) {
        video.current.play();
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [video]);

  useEffect(() => {
    if (video?.current) {
      const videoCurrent = video.current;

      // @ts-expect-error timer
      timeRemainingRef.current = 0;
      setDuration(0);
      setCurrentPos(0);

      const handleLoadedMetadata = () => {
        setDuration(videoCurrent.duration || 0);
      };

      const handleVideoError = () => {
        console.error('Error playing video. Retrying...');
        videoCurrent.load();
      };

      const handleTimeUpdate = () => {
        if (videoCurrent) {
          // @ts-expect-error timer
          timeRemainingRef.current =
            videoCurrent.duration - videoCurrent.currentTime;
        }
      };

      videoCurrent.addEventListener('timeupdate', handleTimeUpdate);
      videoCurrent.addEventListener('error', handleVideoError);
      videoCurrent.addEventListener('loadedmetadata', handleLoadedMetadata);

      onPlaying();

      return () => {
        if (videoCurrent) {
          videoCurrent.removeEventListener('timeupdate', handleTimeUpdate);
          videoCurrent.removeEventListener('error', handleVideoError);
          videoCurrent.removeEventListener(
            'loadedmetadata',
            handleLoadedMetadata,
          );
        }
      };
    }

    return () => {};
  }, [onPlaying, video, videoItem]);

  useEffect(() => {
    if (currentPos && duration && timeRemainingRef.current && video?.current) {
      if (currentPos < timeRemainingRef.current - 10) {
        console.log(
          'Updating current playlist item video - setting to pos: ',
          duration - currentPos,
        );
        video.current.currentTime = duration - currentPos;
      }
    }
  }, [currentPos, duration, video]);

  useSetLiveView({
    component: 'playlistView',
    videoTracks: [],
  });

  return (
    <>
      {videoItem ? (
        <FullscreenVideoContained
          autoPlay={true}
          height={`${100 - lowerThirdsPercentage}vh`}
          id={`mediaVideo_${videoItem.data.id.toString()}`}
          key={`mediaVideo_${videoItem.data.id.toString()}`}
          loop={false}
          onEnded={nextPlaylistItem}
          ref={video}
          src={videoItem.links.pre_record}
          // muted={true}
        />
      ) : null}
    </>
  );
};
